export const translations = {
  'de': {
    'Name': '',
    'Description': '',
    'Submit': '',
    'Items.header': '',
    'Items.header.offline': '',
    'YYYY-MM-DD': '',
    'LoadingContent.message': '',
    'Email': '',
    'Password': '',
    'ForgotPassword': '',
    'ForgotPassword.here': '',
    'NewPassword': '',
    'NewPasswordConfirmation': '',
    'PasswordConfirmation': '',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': '',
    'Home.header.offline': '',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'Company Name': 'Company Name',
    'company name is required': 'company name is required',
    'Email': 'Email',
    'email is required': 'email is required',
    'Billing Address': 'Billing Address',
    'billing address is required': 'billing address is required',
    'Postal Code': 'Postal Code',
    'postal code is required': 'postal code is required',
    'City': 'City',
    'city is required': 'city is required',
    'Country': 'Country',
    'country is required': 'country is required',
    'VAT Number': 'VAT Number',
    'vat id is required': 'vat id is required',
    'Submit': 'Submit',
    'Active': 'Active',
    'Contract Address': 'Contract Address',
    'Token': 'Token',
    'Trait': 'Trait',
    'Unlock Limit': 'Unlock Limit',
    'Boundary Type': 'Boundary Type',
    'Quantity': 'Quantity',
    'Valid From': 'Valid From',
    'Valid Until': 'Valid Until',
    'No Role': 'No Role',
    'Content Admin': 'Content Admin',
    'Token Creator': 'Token Creator',
    'Super Admin': 'Super Admin',
    'Wallet Address': 'Wallet Address',
    'creator_address is required': 'creator_address is required',
    'Maximum length is 42': 'Maximum length is 42',
    'This is not a valid wallet address': 'This is not a valid wallet address',
    'Select Collaborator Role': 'Select Collaborator Role',
    'Maximum length is 254': 'Maximum length is 254',
    'Create': 'Create',
    'Save': 'Save',
    'draft': 'draft',
    'published': 'published',
    'archived': 'archived',
    'member': 'member',
    'holder': 'holder',
    'Event Participants': 'Event Participants',
    'Content Unlocker': 'Content Unlocker',
    'Voter': 'Voter',
    'Title': 'Title',
    'Title is required': 'Title is required',
    'Maximum length is 250': 'Maximum length is 250',
    'Select Recipient Type': 'Select Recipient Type',
    'Select Status': 'Select Status',
    'Content': 'Content',
    'Ask your Dropmanager': 'Ask your Dropmanager',
    'Edit': 'Edit',
    'Details': 'Details',
    'Recommended': 'Recommended',
    'Connect to your MetaMask Wallet': 'Connect to your MetaMask Wallet',
    'Name': 'Name',
    'ok': 'ok',
    'Add Contract': 'Add Contract',
    'Import Contract': 'Import Contract',
    'Select one of your already deployed contracts to import on bowline. This process might take some time, but on the meantime you can continue using the app. The supported blockchains are Ethereum, Goerli, Sepolia, Polygon, Mumbai and Amoy.': 'Select one of your already deployed contracts to import on bowline. This process might take some time, but on the meantime you can continue using the app. The supported blockchains are Ethereum, Goerli, Sepolia, Polygon, Mumbai and Amoy.',
    'Create New Contract': 'Create New Contract',
    'Create a new ERC721 or ERC1155 Contract. Since we are still in beta, our team will guide you through this process and help you to identify your needs. Bowline offers more than 20 built in contract modules that enable you to create a feature-rich smart contract.': 'Create a new ERC721 or ERC1155 Contract. Since we are still in beta, our team will guide you through this process and help you to identify your needs. Bowline offers more than 20 built in contract modules that enable you to create a feature-rich smart contract.',
    'Disabled': 'Disabled',
    'This is an invite only/ beta only feature, please contact us for more information': 'This is an invite only/ beta only feature, please contact us for more information',
    'Contact Us': 'Contact Us',
    'This contract is already used by another bowline project, please contact support if you think this is wrong.': 'This contract is already used by another bowline project, please contact support if you think this is wrong.',
    'You\'ve reached the maximum possible contracts on your license, please contact support if you think this is wrong.': 'You\'ve reached the maximum possible contracts on your license, please contact support if you think this is wrong.',
    'Total Supply': 'Total Supply',
    'Open': 'Open',
    'imported': 'imported',
    'Import': 'Import',
    'Contract Import Error': 'Contract Import Error',
    'Contract Operations': 'Contract Operations',
    'Beta': 'Beta',
    'Those functions are called directly from a user\'s wallet.': 'Those functions are called directly from a user\'s wallet.',
    'DO NOT CHANGE ANYTHING BELOW here if you do not know EXACTLY what you are doing! Your own and your users funds and tokens might be at risk if something is misconfigured!': 'DO NOT CHANGE ANYTHING BELOW here if you do not know EXACTLY what you are doing! Your own and your users funds and tokens might be at risk if something is misconfigured!',
    'Read': 'Read',
    'Write': 'Write',
    'Contract Settings': 'Contract Settings',
    'Contract Views': 'Contract Views',
    'Non Payable': 'Non Payable',
    'Payable': 'Payable',
    'Query': 'Query',
    'Payable Amount': 'Payable Amount',
    'No Params Required': 'No Params Required',
    'View on Etherscan': 'View on Etherscan',
    'View on OpenSea': 'View on OpenSea',
    'Select Chain': 'Select Chain',
    'You are currently connected to a different chain, please switch to the selected chain to view more contract details': 'You are currently connected to a different chain, please switch to the selected chain to view more contract details',
    'Registrations': 'Registrations',
    'Last 24h Signups': 'Last 24h Signups',
    'Average per day': 'Average per day',
    'Allowlist': 'Allowlist',
    'Address': 'Address',
    'Added at': 'Added at',
    'ETH Balance': 'ETH Balance',
    'Source': 'Source',
    'show all': 'show all',
    'Holders ({num})': 'Holders ({num})',
    'Holder since': 'Holder since',
    'Balance': 'Balance',
    'Tokens': 'Tokens',
    'Minted': 'Minted',
    'Start Date': 'Start Date',
    'End Date': 'End Date',
    'Show in dApp': 'Show in dApp',
    'community event': 'community event',
    'partner event': 'partner event',
    'conference': 'conference',
    'irl meetup': 'irl meetup',
    'irl party': 'irl party',
    'virtual meetup': 'virtual meetup',
    'discord event': 'discord event',
    'ask me anything': 'ask me anything',
    'Any': 'Any',
    'All': 'All',
    'once': 'once',
    'once per wallet': 'once per wallet',
    'unlimited': 'unlimited',
    'General Information': 'General Information',
    'Unlock Options': 'Unlock Options',
    'Set Start Date': 'Set Start Date',
    'Set End Date': 'Set End Date',
    'Select Event Type': 'Select Event Type',
    'Select Event Status': 'Select Event Status',
    'Location': 'Location',
    'location': 'location',
    'Public Description': 'Public Description',
    'Create a description that will be visible to every user.': 'Create a description that will be visible to every user.',
    'Unlock Boundaries': 'Unlock Boundaries',
    'Boundaries will limit the access to content for specific tokens.': 'Boundaries will limit the access to content for specific tokens.',
    'Select Boundary Combination': 'Select Boundary Combination',
    'Selecting \'Any\' will unlock the content if any of the boundaries are met. Selecting \'All\' will unlock the content if all of the boundaries are met.': 'Selecting \'Any\' will unlock the content if any of the boundaries are met. Selecting \'All\' will unlock the content if all of the boundaries are met.',
    'Select Limits': 'Select Limits',
    'Boundary': 'Boundary',
    'Unlocks': 'Unlocks',
    'Add Boundaries': 'Add Boundaries',
    'Unlock Inputs': 'Unlock Inputs',
    'Unlock input fields are fields that will be mandatory to fill in to unlock content': 'Unlock input fields are fields that will be mandatory to fill in to unlock content',
    'Input': 'Input',
    'Add Unlock Input': 'Add Unlock Input',
    'Unlock Actions': 'Unlock Actions',
    'Unlock actions are actions that will be triggered once the unlock succeeds': 'Unlock actions are actions that will be triggered once the unlock succeeds',
    'Action': 'Action',
    'Add Unlock Action': 'Add Unlock Action',
    'Importing a new contract will add a new order of {price} Euros': 'Importing a new contract will add a new order of {price} Euros',
    'Clicking on save will add a new order of {price} Euros': 'Clicking on save will add a new order of {price} Euros',
    'You have exceeded your license limit for {resource}': 'You have exceeded your license limit for {resource}',
    'past': 'past',
    'upcoming': 'upcoming',
    'ongoing': 'ongoing',
    'Clear Filters': 'Clear Filters',
    'Filters': 'Filters',
    'LoadingContent.message': 'LoadingContent.message',
    'Drop the files here ...': 'Drop the files here ...',
    'Uploading file...': 'Uploading file...',
    'Uploading successfull': 'Uploading successfull',
    'Public Function Call': 'Public Function Call',
    'Allowlist Entries with Tag': 'Allowlist Entries with Tag',
    'Unique Mint Voucher Codes': 'Unique Mint Voucher Codes',
    'Secret Word Mints': 'Secret Word Mints',
    'Contract Call': 'Contract Call',
    'Bowline Relay Service': 'Bowline Relay Service',
    'Gas Station Network': 'Gas Station Network',
    'This function always returns a static integer value. This could be helpful to set a fixed price or a quantity for example. Once selected, you can set the fixed value.': 'This function always returns a static integer value. This could be helpful to set a fixed price or a quantity for example. Once selected, you can set the fixed value.',
    'This function always returns TRUE. Could be useful to set a static value for mint enabled for example.': 'This function always returns TRUE. Could be useful to set a static value for mint enabled for example.',
    'This function always returns FALSE. Could be useful to set a static value for mint enabled for example.': 'This function always returns FALSE. Could be useful to set a static value for mint enabled for example.',
    'General': 'General',
    'Settings': 'Settings',
    'Smart Contract Abi': 'Smart Contract Abi',
    'Public Title and Description for Mint Tier': 'Public Title and Description for Mint Tier',
    'e.g. Public Mint': 'e.g. Public Mint',
    'Description': 'Description',
    'General Settings for Mint Tier': 'General Settings for Mint Tier',
    'Mint Tier Type': 'Mint Tier Type',
    'Token Identifier': 'Token Identifier',
    'Required if Contract is ERC1155': 'Required if Contract is ERC1155',
    'e.g. 1': 'e.g. 1',
    'Token Identifier is required': 'Token Identifier is required',
    'Internal Tier ID': 'Internal Tier ID',
    'Required for limited bowline mint functions such as allowlist or mint vouchers': 'Required for limited bowline mint functions such as allowlist or mint vouchers',
    'Internal Tier ID is required': 'Internal Tier ID is required',
    'Autogenerate Mintpasses': 'Autogenerate Mintpasses',
    'By default, mint passes are only generated on click. Enabling this options means whenever a tag is added to an wallet that matches this category a valid signature is generated.': 'By default, mint passes are only generated on click. Enabling this options means whenever a tag is added to an wallet that matches this category a valid signature is generated.',
    'Required Tag': 'Required Tag',
    'All wallets with this tag on the allowlist are able to mint. A tag is required. Changing the tag does not invalidate already minted served mint passes. Howerver, all existing mintpasses are going to be deleted on changing this value. WE DO NOT RECOMMEND CHANGING THIS ONCE YOUR MINT IS LIVE.': 'All wallets with this tag on the allowlist are able to mint. A tag is required. Changing the tag does not invalidate already minted served mint passes. Howerver, all existing mintpasses are going to be deleted on changing this value. WE DO NOT RECOMMEND CHANGING THIS ONCE YOUR MINT IS LIVE.',
    'Secret Word': 'Secret Word',
    'Not implemented yet, please talk to your Drop Manager if you want to use this Beta Feature.': 'Not implemented yet, please talk to your Drop Manager if you want to use this Beta Feature.',
    'Unique Mint Vouchers': 'Unique Mint Vouchers',
    'To manage mint vouchers visit the detail view of this mint tier after saving.': 'To manage mint vouchers visit the detail view of this mint tier after saving.',
    'Smart Contract ABI': 'Smart Contract ABI',
    'Mint Tier Delivery Type': 'Mint Tier Delivery Type',
    'Mint Function': 'Mint Function',
    'State Mutability': 'State Mutability',
    'Write required, payable optional': 'Write required, payable optional',
    'Mint Abi is required': 'Mint Abi is required',
    'Price Function': 'Price Function',
    'Should return native value in wei': 'Should return native value in wei',
    'Optional for Free Mints, default: 0': 'Optional for Free Mints, default: 0',
    'Function to check if minting is enabled': 'Function to check if minting is enabled',
    'Should return true or false': 'Should return true or false',
    'Function to check available amount': 'Function to check available amount',
    'Can optional take parameter for receiver address': 'Can optional take parameter for receiver address',
    'Function to check how many are already minted': 'Function to check how many are already minted',
    'page': 'page',
    'external': 'external',
    'Select Content Type': 'Select Content Type',
    'Url': 'Url',
    'Position': 'Position',
    'Visible': 'Visible',
    'Parent Navigation Item': 'Parent Navigation Item',
    'Back': 'Back',
    'Public Content': 'Public Content',
    'Public content will be visible to all users.': 'Public content will be visible to all users.',
    'Partner Name': 'Partner Name',
    'Partner Url': 'Partner Url',
    'Secret Content': 'Secret Content',
    'Secret content will only be visible to admins or the unlocker.': 'Secret content will only be visible to admins or the unlocker.',
    'Secret Deal': 'Secret Deal',
    'Add some text for the user that will unlock your deal.': 'Add some text for the user that will unlock your deal.',
    'You have a pending order since {dueDate}': 'You have a pending order since {dueDate}',
    'You have a pending order since {dueDate}. The public app for this project will be deprecated on {dueTwoWeeks} ': 'You have a pending order since {dueDate}. The public app for this project will be deprecated on {dueTwoWeeks} ',
    'You have a pending order since {dueDate}. The public app for this project already shows a deprecation warning since {dueTwoWeeks} and it will be deactivated {dueThreeWeeks} ': 'You have a pending order since {dueDate}. The public app for this project already shows a deprecation warning since {dueTwoWeeks} and it will be deactivated {dueThreeWeeks} ',
    'You have a pending order since {dueDate}. The public app for this project is currently deactivated! ': 'You have a pending order since {dueDate}. The public app for this project is currently deactivated! ',
    'Please check your invoices': 'Please check your invoices',
    'Please check your invoices!': 'Please check your invoices!',
    'Please check your invoices!!': 'Please check your invoices!!',
    'Please check your invoices!!!': 'Please check your invoices!!!',
    'You have some pending orders': 'You have some pending orders',
    'Your project is currently deactivated': 'Your project is currently deactivated',
    'Please check your invoices and pay the pending orders to reactivate your project.': 'Please check your invoices and pay the pending orders to reactivate your project.',
    'To delete your account please contact us at': 'To delete your account please contact us at',
    'support@bowline.app': 'support@bowline.app',
    'Please select one of the available plans': 'Please select one of the available plans',
    'We haven’t found any Pass in your Wallet': 'We haven’t found any Pass in your Wallet',
    'You need to activate your Bowline license.': 'You need to activate your Bowline license.',
    'This Project has no active license': 'This Project has no active license',
    'Please select a Project Plan': 'Please select a Project Plan',
    'Use NFT pass': 'Use NFT pass',
    'Select Payment Method': 'Select Payment Method',
    'Selected Plan': 'Selected Plan',
    'month': 'month',
    'Invoice': 'Invoice',
    'Credit Card': 'Credit Card',
    'Ethereum': 'Ethereum',
    'Select Method': 'Select Method',
    'Billing Info': 'Billing Info',
    'Not active yet': 'Not active yet',
    'If you want to pay with {method} please contact us via mail': 'If you want to pay with {method} please contact us via mail',
    'creditcard': 'creditcard',
    'Activating pass': 'Activating pass',
    'Select one of these passes to bind it to your project.': 'Select one of these passes to bind it to your project.',
    'Activate Pass': 'Activate Pass',
    'Project is activated': 'Project is activated',
    'This subscription will be automatically renewed on {date}.': 'This subscription will be automatically renewed on {date}.',
    'You can cancel the auto-renewal at any time and your subscription will still be valid until expiration': 'You can cancel the auto-renewal at any time and your subscription will still be valid until expiration',
    'Unsubscribe': 'Unsubscribe',
    'This subscription will be valid until {date}.': 'This subscription will be valid until {date}.',
    'You can activate the auto-renewal at any time.': 'You can activate the auto-renewal at any time.',
    'Activate auto-renewal': 'Activate auto-renewal',
    'Close': 'Close',
    'Confirm Activation': 'Confirm Activation',
    'As soon as your Bowline Pass is assigned to a Project and activated, it cannot be assigned to any other Bowline Project without first being deactivated from the current one. Each pass can be resold at NFT Marketplaces. With the duration of it being the remaining time period available at the moment of the sale.': 'As soon as your Bowline Pass is assigned to a Project and activated, it cannot be assigned to any other Bowline Project without first being deactivated from the current one. Each pass can be resold at NFT Marketplaces. With the duration of it being the remaining time period available at the moment of the sale.',
    'As long as a Pass is activated and assigned to a Project, it cannot be sold at NFT Marketplaces. It must be deactivated before continuing with the sale.': 'As long as a Pass is activated and assigned to a Project, it cannot be sold at NFT Marketplaces. It must be deactivated before continuing with the sale.',
    'Confirm': 'Confirm',
    'Cancel': 'Cancel',
    'Monthly': 'Monthly',
    'Yearly': 'Yearly',
    'monthly payment': 'monthly payment',
    'annual payment': 'annual payment',
    'Included': 'Included',
    'Contracts': 'Contracts',
    'Collaborators': 'Collaborators',
    'topups': 'topups',
    'Per additional 721 token': 'Per additional 721 token',
    'Per additional 1155 token': 'Per additional 1155 token',
    'Additional contract per month': 'Additional contract per month',
    'Additional collaborator per month': 'Additional collaborator per month',
    'included unlockables': 'included unlockables',
    'Contents': 'Contents',
    'Votings': 'Votings',
    'Partner Deals': 'Partner Deals',
    'Events': 'Events',
    'additional unlockables': 'additional unlockables',
    'Additional Content per month': 'Additional Content per month',
    'Additional Event per month': 'Additional Event per month',
    'Additional voting per month': 'Additional voting per month',
    'Additional Deal per month': 'Additional Deal per month',
    'Minting': 'Minting',
    'Minter Pays': 'Minter Pays',
    'Can be covered by creator optionally': 'Can be covered by creator optionally',
    'Price per mint': 'Price per mint',
    'Customization Limitations': 'Customization Limitations',
    'show less': 'show less',
    'show more': 'show more',
    'select': 'select',
    'Bound': 'Bound',
    'Max days': 'Max days',
    'Valid until': 'Valid until',
    'MM/DD/YYYY': 'MM/DD/YYYY',
    'Pass bound to other Project': 'Pass bound to other Project',
    'Custom Fonts': 'Custom Fonts',
    'Default Fonts': 'Default Fonts',
    'Typography': 'Typography',
    'Upload Custom Font': 'Upload Custom Font',
    'You can now select your uploaded font in the dropdowns below.': 'You can now select your uploaded font in the dropdowns below.',
    'I have a license to use this font commercially': 'I have a license to use this font commercially',
    'Upload': 'Upload',
    'Custom font file should be in .ttf format.': 'Custom font file should be in .ttf format.',
    'Select Body Font': 'Select Body Font',
    'Select Heading Font': 'Select Heading Font',
    'Colors': 'Colors',
    'General Colors': 'General Colors',
    'Body Color': 'Body Color',
    'Background Color': 'Background Color',
    'Background Highlight Color': 'Background Highlight Color',
    'Link Color': 'Link Color',
    'Link Hover Color': 'Link Hover Color',
    'Muted Color': 'Muted Color',
    'Brand Colors': 'Brand Colors',
    'Primary Color': 'Primary Color',
    'Secondary Color': 'Secondary Color',
    'Success Color': 'Success Color',
    'Info Color': 'Info Color',
    'Warning Color': 'Warning Color',
    'Danger Color': 'Danger Color',
    'Light Color': 'Light Color',
    'Dark Color': 'Dark Color',
    'Navbar': 'Navbar',
    'Navbar Color': 'Navbar Color',
    'Navbar Hover Color': 'Navbar Hover Color',
    'Navbar Active Color': 'Navbar Active Color',
    'Navbar Background Color': 'Navbar Background Color',
    'Navbar Brand Color': 'Navbar Brand Color',
    'Navbar Brand Hover Color': 'Navbar Brand Hover Color',
    'Footer': 'Footer',
    'Footer Background Color': 'Footer Background Color',
    'Footer Color': 'Footer Color',
    'Cards': 'Cards',
    'Card Color': 'Card Color',
    'Card Background Color': 'Card Background Color',
    'Card Border Color': 'Card Border Color',
    'Borders': 'Borders',
    'Border Color': 'Border Color',
    'Border Width': 'Border Width',
    'Border Radius': 'Border Radius',
    'Border Radius sm': 'Border Radius sm',
    'Border Radius lg': 'Border Radius lg',
    'Border Radius xl': 'Border Radius xl',
    'Border Radius 2xl': 'Border Radius 2xl',
    'Miscellaneous': 'Miscellaneous',
    'Bowline Branding': 'Bowline Branding',
    'cancel': 'cancel',
    'Monthly Subscription': 'Monthly Subscription',
    'Yearly Subscription': 'Yearly Subscription',
    'Recurrent': 'Recurrent',
    'One Time': 'One Time',
    'Additional 721 Token': 'Additional 721 Token',
    'Additional 1155 Token': 'Additional 1155 Token',
    'Additional Contract': 'Additional Contract',
    'Additional Content': 'Additional Content',
    'Additional Event': 'Additional Event',
    'Additional Voting': 'Additional Voting',
    'Additional Deal': 'Additional Deal',
    'Additional Collaborator': 'Additional Collaborator',
    'Project Name': 'Project Name',
    'The title you want to give your project': 'The title you want to give your project',
    'Domain': 'Domain',
    'optional': 'optional',
    'You can\'t change this later on your own': 'You can\'t change this later on your own',
    'Your project will be reachable under': 'Your project will be reachable under',
    'Or you can set up a custom Domain later (only if included in your pass)': 'Or you can set up a custom Domain later (only if included in your pass)',
    'Finish setup': 'Finish setup',
    'disabled': 'disabled',
    'not required': 'not required',
    'any required': 'any required',
    'real required': 'real required',
    'Anyone': 'Anyone',
    'Connected Wallets': 'Connected Wallets',
    'Signed Wallets': 'Signed Wallets',
    'Holder Wallets': 'Holder Wallets',
    'Password': 'Password',
    'Mainnet': 'Mainnet',
    'Ropsten': 'Ropsten',
    'Goerli': 'Goerli',
    'Kovan': 'Kovan',
    'Polygon': 'Polygon',
    'Mumbai': 'Mumbai',
    'Sepolia': 'Sepolia',
    'Amoy': 'Amoy',
    'No header': 'No header',
    'Only logo and wallet': 'Only logo and wallet',
    'Centered logo with burger': 'Centered logo with burger',
    'Sidebar': 'Sidebar',
    'Top slim': 'Top slim',
    'Top unsticky': 'Top unsticky',
    'Offcanvas Sidebar': 'Offcanvas Sidebar',
    'Floating Sidebar': 'Floating Sidebar',
    'No footer': 'No footer',
    'Slim': 'Slim',
    'Big': 'Big',
    'Project Slug': 'Project Slug',
    'Vanity Domain': 'Vanity Domain',
    'Select Email sharing options': 'Select Email sharing options',
    'Receiver Email Address': 'Receiver Email Address',
    'Receiver Email': 'Receiver Email',
    'Email is required': 'Email is required',
    'Public App Access Mode': 'Public App Access Mode',
    'Project Default Network': 'Project Default Network',
    'Web3Auth Id': 'Web3Auth Id',
    'Web3Auth Google Id': 'Web3Auth Google Id',
    'Web3Auth Google Verifier': 'Web3Auth Google Verifier',
    'Web3Auth Facebook Id': 'Web3Auth Facebook Id',
    'Web3Auth Facebook Verifier': 'Web3Auth Facebook Verifier',
    'SHA-256 Hashed Password': 'SHA-256 Hashed Password',
    'Your Dapp is only visible to people that know the password. It\'s not possible to access any page while the password is set. However, the api might still expose information about your project.': 'Your Dapp is only visible to people that know the password. It\'s not possible to access any page while the password is set. However, the api might still expose information about your project.',
    'Header Layout': 'Header Layout',
    'Footer Layout': 'Footer Layout',
    'Available Languages': 'Available Languages',
    'project welcome text': 'project welcome text',
    'Create the welcome text for your users': 'Create the welcome text for your users',
    'Project Terms of Service': 'Project Terms of Service',
    'Create the tos for your project': 'Create the tos for your project',
    'Data Privacy': 'Data Privacy',
    'Create the data privacy for your project': 'Create the data privacy for your project',
    'Password (confirmation)': 'Password (confirmation)',
    'Create New': 'Create New',
    'Unlockable': 'Unlockable',
    'Create New Unlockable': 'Create New Unlockable',
    'Voting': 'Voting',
    'Create New Voting': 'Create New Voting',
    'Event': 'Event',
    'Create New Event': 'Create New Event',
    'Partner Deal': 'Partner Deal',
    'Create New Partner Deal': 'Create New Partner Deal',
    'Announcement': 'Announcement',
    'Create New Announcement': 'Create New Announcement',
    'Create New Token': 'Create New Token',
    'Contract': 'Contract',
    'Dasboard': 'Dasboard',
    'Dashboard': 'Dashboard',
    'Gated Contents': 'Gated Contents',
    'Unlockables': 'Unlockables',
    'Discussions': 'Discussions',
    'dApp': 'dApp',
    'Appearance': 'Appearance',
    'Navigation Items': 'Navigation Items',
    'Pages': 'Pages',
    'Shop': 'Shop',
    'Private Exchange': 'Private Exchange',
    'Community': 'Community',
    'Holders': 'Holders',
    'Announcements': 'Announcements',
    'Utility Contracts': 'Utility Contracts',
    'Mint Tiers': 'Mint Tiers',
    'Analytics': 'Analytics',
    'Content Categories': 'Content Categories',
    'Wallet Profile Inputs': 'Wallet Profile Inputs',
    'Basic Project Settings': 'Basic Project Settings',
    'Collaborators Management': 'Collaborators Management',
    'Billing Address Management': 'Billing Address Management',
    'Orders And Imvoices Management': 'Orders And Imvoices Management',
    'View dApp': 'View dApp',
    'Log Out': 'Log Out',
    'Projects': 'Projects',
    'Add Project': 'Add Project',
    'Sign in with Wallet': 'Sign in with Wallet',
    'Step': 'Step',
    'a content category': 'a content category',
    'an unlockable content': 'an unlockable content',
    'a whitelist entry': 'a whitelist entry',
    'a voting': 'a voting',
    'a event': 'a event',
    'a project setting': 'a project setting',
    'created': 'created',
    'updated': 'updated',
    'Success!': 'Success!',
    'You successfully {action} {resourceType}': 'You successfully {action} {resourceType}',
    'click to get back to overview screen': 'click to get back to overview screen',
    'Manage Collaborator': 'Manage Collaborator',
    'Manage Category': 'Manage Category',
    'Edit Trait Types': 'Edit Trait Types',
    'Metadata is not manageable': 'Metadata is not manageable',
    'Whale': 'Whale',
    'Remove': 'Remove',
    'View': 'View',
    'True': 'True',
    'False': 'False',
    'Show external': 'Show external',
    'Manage Navigation Item': 'Manage Navigation Item',
    'Manage Trait Type': 'Manage Trait Type',
    'Unlock Event Details': 'Unlock Event Details',
    'Unlockable Type': 'Unlockable Type',
    'Unlockable Title': 'Unlockable Title',
    'Unlock Time': 'Unlock Time',
    'Unlocker': 'Unlocker',
    'Inputs': 'Inputs',
    'Manage Profile Input': 'Manage Profile Input',
    'Block user': 'Block user',
    'Unblock user': 'Unblock user',
    'Create Token': 'Create Token',
    'WARNING': 'WARNING',
    'You dont have any bowline contracts where you could create a token, ask your drop manager to add a contract to your project.': 'You dont have any bowline contracts where you could create a token, ask your drop manager to add a contract to your project.',
    'Single Token': 'Single Token',
    'With the single token creation, you can easily create a new NFT for your collection. With only a few steps, your token is ready to be minted.': 'With the single token creation, you can easily create a new NFT for your collection. With only a few steps, your token is ready to be minted.',
    'Mass Token Generation': 'Mass Token Generation',
    'With the mass token generation, you can create a whole NFT collection within a few moments. Simply set up the collection\'s details and select the amount of tokens to be generated by our tool.': 'With the mass token generation, you can create a whole NFT collection within a few moments. Simply set up the collection\'s details and select the amount of tokens to be generated by our tool.',
    'This is an invite only/ beta only feature, please contact the Drop Manager for more information.': 'This is an invite only/ beta only feature, please contact the Drop Manager for more information.',
    'Upload Media': 'Upload Media',
    'Traits': 'Traits',
    'Review': 'Review',
    'Token Name': 'Token Name',
    'Token Description': 'Token Description',
    'Image Upload': 'Image Upload',
    'Animation Upload': 'Animation Upload',
    'Add Trait': 'Add Trait',
    'Blockchain Details': 'Blockchain Details',
    'Token Standard': 'Token Standard',
    'Blockchain': 'Blockchain',
    'Save as Draft': 'Save as Draft',
    'Publish': 'Publish',
    'Next': 'Next',
    'Select Trait Types': 'Select Trait Types',
    'Select Traits': 'Select Traits',
    'Type': 'Type',
    'From': 'From',
    'To': 'To',
    'Date': 'Date',
    'Image': 'Image',
    'Animation': 'Animation',
    'Missing Name': 'Missing Name',
    'Select': 'Select',
    'Selected': 'Selected',
    'Metadata is not manageable for this contract': 'Metadata is not manageable for this contract',
    'Fill out the details to create an trait.': 'Fill out the details to create an trait.',
    'Edit Trait': 'Edit Trait',
    'Fill out the details for this Trait.': 'Fill out the details for this Trait.',
    'Delete Trait': 'Delete Trait',
    'Do you really want to delete this trait?': 'Do you really want to delete this trait?',
    'Delete': 'Delete',
    'Display Type': 'Display Type',
    'N/A': 'N/A',
    'Value': 'Value',
    'value is required': 'value is required',
    'string': 'string',
    'number': 'number',
    'boost percentage': 'boost percentage',
    'boost number': 'boost number',
    'date': 'date',
    'name is required': 'name is required',
    'Select display Type': 'Select display Type',
    'Created': 'Created',
    'Updated': 'Updated',
    'text': 'text',
    'video': 'video',
    'download': 'download',
    'external Link': 'external Link',
    'Select Content Category': 'Select Content Category',
    'Content Category is required': 'Content Category is required',
    'Select Content Status': 'Select Content Status',
    'Secret Description': 'Secret Description',
    'Add some text for the user that will unlock your content.': 'Add some text for the user that will unlock your content.',
    'notify by mail': 'notify by mail',
    'api call': 'api call',
    'contract method': 'contract method',
    'Action Type': 'Action Type',
    'Select Type': 'Select Type',
    'Email Receiver': 'Email Receiver',
    'Select Contract': 'Select Contract',
    'Select tokens': 'Select tokens',
    'Add Trait Boundaries': 'Add Trait Boundaries',
    'Number Of Tokens': 'Number Of Tokens',
    'Wallet': 'Wallet',
    'Sign up Date': 'Sign up Date',
    'Required': 'Required',
    'true': 'true',
    'false': 'false',
    'selection': 'selection',
    'radio': 'radio',
    'checkbox': 'checkbox',
    'switch': 'switch',
    'email': 'email',
    'url': 'url',
    'Input Type': 'Input Type',
    'Size': 'Size',
    'Size must be a positive number': 'Size must be a positive number',
    'Order': 'Order',
    'Order must be a positive number': 'Order must be a positive number',
    'Change password': 'Change password',
    'Insert your current password and the new password': 'Insert your current password and the new password',
    'Current Password': 'Current Password',
    'Smart Contract': 'Smart Contract',
    'Upgrade your license to have access to this feature.': 'Upgrade your license to have access to this feature.',
    'immediate': 'immediate',
    'after vote': 'after vote',
    'after end': 'after end',
    'Your voting is already live! Please keep this in mind while doing changes!': 'Your voting is already live! Please keep this in mind while doing changes!',
    'Select Reveal Type': 'Select Reveal Type',
    'Select Voting Status': 'Select Voting Status',
    'Name is required': 'Name is required',
    'Select Input Type': 'Select Input Type',
    'Account setup': 'Account setup',
    'Welcome to Bowline': 'Welcome to Bowline',
    'From Beverage Manufacturers and Fashion Brands to Artists and Creatives; We are glad to onboard you onto Bowline and guide you through this open sea of digital collectibles and memberships, to our safe harbour.': 'From Beverage Manufacturers and Fashion Brands to Artists and Creatives; We are glad to onboard you onto Bowline and guide you through this open sea of digital collectibles and memberships, to our safe harbour.',
    'Let your journey with Bowline begin!': 'Let your journey with Bowline begin!',
    'Start Set-up': 'Start Set-up',
    'Please confirm!': 'Please confirm!',
    'Code sent to': 'Code sent to',
    'Resend': 'Resend',
    'Change E-mail': 'Change E-mail',
    'Please enter it below.': 'Please enter it below.',
    'Verify': 'Verify',
    'What is your E-Mail Address?': 'What is your E-Mail Address?',
    'Your email address is necessary for our support team, in order to be able to contact you directly in any case. It is also necessary since as it is part of our Contract Ownership Verification process.': 'Your email address is necessary for our support team, in order to be able to contact you directly in any case. It is also necessary since as it is part of our Contract Ownership Verification process.',
    'Send Code': 'Send Code',
    'Role': 'Role',
    'Added At': 'Added At',
    'Invite Collaborator': 'Invite Collaborator',
    'Your plan allows you to have up to {maxCollaborators} collaborators for free. You currently have {currentCollaborators} collaborators.': 'Your plan allows you to have up to {maxCollaborators} collaborators for free. You currently have {currentCollaborators} collaborators.',
    'Delete Collaborator': 'Delete Collaborator',
    'Do you want to delete this collaborator?': 'Do you want to delete this collaborator?',
    'Collaborator was deleted successfully!': 'Collaborator was deleted successfully!',
    'Create Collaborator': 'Create Collaborator',
    'There was a problem creating the collaborator, please contact support on discord': 'There was a problem creating the collaborator, please contact support on discord',
    'Collaborator Created': 'Collaborator Created',
    'The collaborator has been created.': 'The collaborator has been created.',
    'Show Collaborator': 'Show Collaborator',
    'Collaborator': 'Collaborator',
    'Edit Collaborator': 'Edit Collaborator',
    'There was a problem showing this collaborator. Please contact support on discord': 'There was a problem showing this collaborator. Please contact support on discord',
    'Collaborator Updated': 'Collaborator Updated',
    'The collaborator has been updated.': 'The collaborator has been updated.',
    'Create Community Announcement': 'Create Community Announcement',
    'Community Announcements': 'Community Announcements',
    'Fill out the details to create announcement.': 'Fill out the details to create announcement.',
    'There was a problem creating the announcement, please contact support on discord': 'There was a problem creating the announcement, please contact support on discord',
    'Announcement Created': 'Announcement Created',
    'The announcement has been created.': 'The announcement has been created.',
    'Show Community Announcement': 'Show Community Announcement',
    'Community Announcement': 'Community Announcement',
    'Edit Community Announcement': 'Edit Community Announcement',
    'Announcement Title': 'Announcement Title',
    'Status': 'Status',
    'Recipient Type': 'Recipient Type',
    'There was a problem showing this unlockable content. Please contact support on discord': 'There was a problem showing this unlockable content. Please contact support on discord',
    'Fill out the details for this Community Announcement.': 'Fill out the details for this Community Announcement.',
    'Announcement Updated': 'Announcement Updated',
    'The announcement has been updated.': 'The announcement has been updated.',
    'There was a problem showing this announcement. Please contact support on discord': 'There was a problem showing this announcement. Please contact support on discord',
    'Create Community Announcements': 'Create Community Announcements',
    'There are no community announcements yet': 'There are no community announcements yet',
    'Publish Date': 'Publish Date',
    'Creation Date': 'Creation Date',
    'There was a problem listing all the community announcements. Please contact support on discord': 'There was a problem listing all the community announcements. Please contact support on discord',
    'Create Content Category': 'Create Content Category',
    'There was a problem creating the content category, please contact support on discord': 'There was a problem creating the content category, please contact support on discord',
    'Content Category Created': 'Content Category Created',
    'The content category has been created.': 'The content category has been created.',
    'Content Category': 'Content Category',
    'Category': 'Category',
    'Content Category Updated': 'Content Category Updated',
    'The content category has been updated.': 'The content category has been updated.',
    'There was a problem updating the content category, please contact support on discord': 'There was a problem updating the content category, please contact support on discord',
    'Symbol': 'Symbol',
    'Network': 'Network',
    'Supported Interfaces': 'Supported Interfaces',
    'Header Image': 'Header Image',
    'Logo': 'Logo',
    'Search': 'Search',
    'Search for address, name, symbol': 'Search for address, name, symbol',
    'network': 'network',
    'Contracts Import': 'Contracts Import',
    'Change Image': 'Change Image',
    'Add Image': 'Add Image',
    'Show Event': 'Show Event',
    'Edit Event': 'Edit Event',
    'Unlock Options & Inputs': 'Unlock Options & Inputs',
    'Event Type': 'Event Type',
    'Public Medium': 'Public Medium',
    'Boundary Combination': 'Boundary Combination',
    'Boundaries': 'Boundaries',
    'None': 'None',
    'Event Sign-up\'s': 'Event Sign-up\'s',
    'Tags': 'Tags',
    'Activity Status': 'Activity Status',
    'Create Event': 'Create Event',
    'Fill out the details to create an event.': 'Fill out the details to create an event.',
    'There was a problem creating the event, please contact support on discord': 'There was a problem creating the event, please contact support on discord',
    'Event Created': 'Event Created',
    'The event has been created.': 'The event has been created.',
    'Fill out the details for this Event.': 'Fill out the details for this Event.',
    'Event Updated': 'Event Updated',
    'The event has been updated.': 'The event has been updated.',
    'There was a problem showing this event. Please contact support on discord': 'There was a problem showing this event. Please contact support on discord',
    'Tag': 'Tag',
    'Token Balance': 'Token Balance',
    'Show Mint Tier': 'Show Mint Tier',
    'Mint Tier': 'Mint Tier',
    'Edit Mint Tier': 'Edit Mint Tier',
    'ABI Overview': 'ABI Overview',
    'Mint Vouchers': 'Mint Vouchers',
    'Available From': 'Available From',
    'Internal Tier Id': 'Internal Tier Id',
    'Tier Type': 'Tier Type',
    'Tier Delivery Type': 'Tier Delivery Type',
    'Abi Overview': 'Abi Overview',
    'Price Abi': 'Price Abi',
    'Available amount Abi': 'Available amount Abi',
    'Mint Abi': 'Mint Abi',
    'Enabled Abi': 'Enabled Abi',
    'Already minted amount abi': 'Already minted amount abi',
    'Search for title, contract address, contract name': 'Search for title, contract address, contract name',
    'Create MintTier': 'Create MintTier',
    'Create Mint Tier': 'Create Mint Tier',
    'Fill out the details to create content.': 'Fill out the details to create content.',
    'There was a problem creating the tiers, please contact support on discord': 'There was a problem creating the tiers, please contact support on discord',
    'Mint Tier Created': 'Mint Tier Created',
    'The mint tier has been created.': 'The mint tier has been created.',
    'Fill out the details for this MintTier.': 'Fill out the details for this MintTier.',
    'Mint Tier Updated': 'Mint Tier Updated',
    'The mint tier has been updated.': 'The mint tier has been updated.',
    'There was a problem showing this tier. Please contact support on discord': 'There was a problem showing this tier. Please contact support on discord',
    'Content Type': 'Content Type',
    'Create Navigation Item': 'Create Navigation Item',
    'Delete Navigation Item': 'Delete Navigation Item',
    'Do you want to delete this Navigation Item?': 'Do you want to delete this Navigation Item?',
    'Navigation Item was deleted successfully!': 'Navigation Item was deleted successfully!',
    'Continue': 'Continue',
    'There was a problem creating the navigation item, please contact support on discord': 'There was a problem creating the navigation item, please contact support on discord',
    'Navigation Item Created': 'Navigation Item Created',
    'The navigation item has been created.': 'The navigation item has been created.',
    'Navigation Item': 'Navigation Item',
    'Navigation Item Updated': 'Navigation Item Updated',
    'The navigation item has been updated.': 'The navigation item has been updated.',
    'Creator App Sign In': 'Creator App Sign In',
    'Don\'t have a wallet yet? See here how to create it': 'Don\'t have a wallet yet? See here how to create it',
    'With the click on connect your wallet you accept our terms and conditions': 'With the click on connect your wallet you accept our terms and conditions',
    'You are asked to sign a message to generate a secure login connection for your wallet. We won\'t have access to your funds, NFTs or perform any actions with this information.': 'You are asked to sign a message to generate a secure login connection for your wallet. We won\'t have access to your funds, NFTs or perform any actions with this information.',
    'Terms and conditions': 'Terms and conditions',
    'Page Created': 'Page Created',
    'The page has been created.': 'The page has been created.',
    'There was a problem creating the page, please contact support on discord': 'There was a problem creating the page, please contact support on discord',
    'Page Updated': 'Page Updated',
    'The page has been updated.': 'The page has been updated.',
    'There was a problem showing this page. Please contact support on discord': 'There was a problem showing this page. Please contact support on discord',
    'Slug': 'Slug',
    'Page Type': 'Page Type',
    'Create Page': 'Create Page',
    'There are no pages yet': 'There are no pages yet',
    'Create Partner Deal': 'Create Partner Deal',
    'There are no partner deals yet': 'There are no partner deals yet',
    'Fill out the details to create deal.': 'Fill out the details to create deal.',
    'There was a problem creating the partner deal, please contact support on discord': 'There was a problem creating the partner deal, please contact support on discord',
    'Partner Deal Created': 'Partner Deal Created',
    'The partner deal has been created.': 'The partner deal has been created.',
    'Show Partner Deal': 'Show Partner Deal',
    'Edit Partner Deal': 'Edit Partner Deal',
    'Partner URL': 'Partner URL',
    'Fill out the details for this Partner Deal.': 'Fill out the details for this Partner Deal.',
    'Partner Deal Updated': 'Partner Deal Updated',
    'The partner deal has been updated.': 'The partner deal has been updated.',
    'There was a problem showing this partner deal. Please contact support on discord': 'There was a problem showing this partner deal. Please contact support on discord',
    'Mint Report': 'Mint Report',
    'Allowlist Report': 'Allowlist Report',
    'Welcome back': 'Welcome back',
    'Please select your project.': 'Please select your project.',
    '{whitelist_entries_count} wallets on allowlist': '{whitelist_entries_count} wallets on allowlist',
    '{wallets_count} wallets registered': '{wallets_count} wallets registered',
    '{contracts_count} contracts': '{contracts_count} contracts',
    '{tokens_count} Tokens': '{tokens_count} Tokens',
    'Fill out the details to change the billing infos for your project.': 'Fill out the details to change the billing infos for your project.',
    'There was a problem showing the project billing info. Please contact support on discord': 'There was a problem showing the project billing info. Please contact support on discord',
    'Project Billing Info Updated': 'Project Billing Info Updated',
    'The project billing info has been updated.': 'The project billing info has been updated.',
    'Payment Provider': 'Payment Provider',
    'Project Orders': 'Project Orders',
    'Price': 'Price',
    'Due Date': 'Due Date',
    'Renewed At': 'Renewed At',
    'Created At': 'Created At',
    'Fill out the details to change the designs for your project.': 'Fill out the details to change the designs for your project.',
    'There was a problem showing the project design. Please contact support on discord': 'There was a problem showing the project design. Please contact support on discord',
    'Project Design Updated': 'Project Design Updated',
    'The project design has been updated.': 'The project design has been updated.',
    'Change Project Settings': 'Change Project Settings',
    'Project Settings': 'Project Settings',
    'Basic Settings': 'Basic Settings',
    'Change Project Design': 'Change Project Design',
    'Project Design': 'Project Design',
    'Fill out the details to change the settings for your project.': 'Fill out the details to change the settings for your project.',
    'There was a problem showing the project settings. Please contact support on discord': 'There was a problem showing the project settings. Please contact support on discord',
    'Project Settings Updated': 'Project Settings Updated',
    'The project settings has been updated.': 'The project settings has been updated.',
    'Quickstart': 'Quickstart',
    'Create a new Bowline Project': 'Create a new Bowline Project',
    'Thanks! Now create a Bowline Project': 'Thanks! Now create a Bowline Project',
    'Whether you already have a smart contract or not, you can create your NFT Project with Bowline and kick off your journey. If you are looking into building a community led project please select one of our partner contracts.': 'Whether you already have a smart contract or not, you can create your NFT Project with Bowline and kick off your journey. If you are looking into building a community led project please select one of our partner contracts.',
    'Please select': 'Please select',
    'I already have a contract': 'I already have a contract',
    'I don\'t have a contract yet': 'I don\'t have a contract yet',
    'Skip this step.': 'Skip this step.',
    'Use one of our partner contracts': 'Use one of our partner contracts',
    'Awesome. Please select one contract': 'Awesome. Please select one contract',
    'Fine. Then lets setup a new project.': 'Fine. Then lets setup a new project.',
    'Just some last steps to finalise the setup.': 'Just some last steps to finalise the setup.',
    'There was a problem creating the token, please contact support on discord': 'There was a problem creating the token, please contact support on discord',
    'Token Created': 'Token Created',
    'The token has been created.': 'The token has been created.',
    'Missing name': 'Missing name',
    'Token Detail': 'Token Detail',
    'Owned by': 'Owned by',
    'View on Rarible': 'View on Rarible',
    'View on LooksRare': 'View on LooksRare',
    'Refresh Metadata & Image': 'Refresh Metadata & Image',
    'Refresh Metadata': 'Refresh Metadata',
    'View external item': 'View external item',
    'View in app': 'View in app',
    'Metadata is getting refreshed in the background. Come back in a few moments': 'Metadata is getting refreshed in the background. Come back in a few moments',
    'Token ID': 'Token ID',
    'History': 'History',
    'Deals': 'Deals',
    'Updated At': 'Updated At',
    'Owner': 'Owner',
    'Trait Types': 'Trait Types',
    'Grid view will get introduced at a later time': 'Grid view will get introduced at a later time',
    'Last Price': 'Last Price',
    'Create TraitType': 'Create TraitType',
    'Edit Trait Type': 'Edit Trait Type',
    'Create Trait Type': 'Create Trait Type',
    'Fill out the details to create an Trait Type.': 'Fill out the details to create an Trait Type.',
    'There was a problem creating the trait type, please contact support on discord': 'There was a problem creating the trait type, please contact support on discord',
    'Trait Type Created': 'Trait Type Created',
    'The trait type has been created.': 'The trait type has been created.',
    'Fill out the details for this Traittype.': 'Fill out the details for this Traittype.',
    'Trait Type Updated': 'Trait Type Updated',
    'The trait type has been updated.': 'The trait type has been updated.',
    'There was a problem showing this trait type. Please contact support on discord': 'There was a problem showing this trait type. Please contact support on discord',
    'Edit Content': 'Edit Content',
    'Secret Medium': 'Secret Medium',
    'Create Unlockable': 'Create Unlockable',
    'There are no unlockable contents yet': 'There are no unlockable contents yet',
    'Last Update': 'Last Update',
    'There was a problem creating the unlockable content, please contact support on discord': 'There was a problem creating the unlockable content, please contact support on discord',
    'Unlockable Content Created': 'Unlockable Content Created',
    'The unlockable content has been created.': 'The unlockable content has been created.',
    'Fill out the details for this Unlockable.': 'Fill out the details for this Unlockable.',
    'Unlockable Content Updated': 'Unlockable Content Updated',
    'The unlockable content has been updated.': 'The unlockable content has been updated.',
    'Show Utility Contract': 'Show Utility Contract',
    'Contract Type': 'Contract Type',
    'Search for address': 'Search for address',
    'Create Utility Contract': 'Create Utility Contract',
    'ForOFour.message': 'ForOFour.message',
    'ForOFour.button': 'ForOFour.button',
    'This Feature is not Included in your License': 'This Feature is not Included in your License',
    'You need to upgrade your license to use this feature. If you face any issues of have questions about this feature please contact our support team.': 'You need to upgrade your license to use this feature. If you face any issues of have questions about this feature please contact our support team.',
    'Please contact our support by email: support@bowline.app.': 'Please contact our support by email: support@bowline.app.',
    'Show Voting': 'Show Voting',
    'Edit Voting': 'Edit Voting',
    'Voting Options': 'Voting Options',
    'Option': 'Option',
    'Overview': 'Overview',
    'Vote History': 'Vote History',
    'Reveal Type': 'Reveal Type',
    ' Vote History': ' Vote History',
    'Votes for Option': 'Votes for Option',
    'Number of total votes for this voting:': 'Number of total votes for this voting:',
    'Vote Date': 'Vote Date',
    'Create Voting': 'Create Voting',
    'Fill out the details to create a Voting.': 'Fill out the details to create a Voting.',
    'There was a problem creating the voting, please contact support on discord': 'There was a problem creating the voting, please contact support on discord',
    'Voting Created': 'Voting Created',
    'The voting has been created.': 'The voting has been created.',
    'Fill out the details for this Voting.': 'Fill out the details for this Voting.',
    'Voting Updated': 'Voting Updated',
    'The voting has been updated.': 'The voting has been updated.',
    'There was a problem showing this voting. Please contact support on discord': 'There was a problem showing this voting. Please contact support on discord',
    'Create Wallet Profile Input': 'Create Wallet Profile Input',
    'Input Order': 'Input Order',
    'There was a problem creating the wallet profile input, please contact support on discord': 'There was a problem creating the wallet profile input, please contact support on discord',
    'Wallet Profile Input Created': 'Wallet Profile Input Created',
    'The wallet profile input has been created.': 'The wallet profile input has been created.',
    'Wallet Profile Input': 'Wallet Profile Input',
    'Wallet Profile Input Updated': 'Wallet Profile Input Updated',
    'The wallet profile input has been updated.': 'The wallet profile input has been updated.',
    'Wallet Detail': 'Wallet Detail',
    'Info': 'Info',
    'Comments': 'Comments',
    'Write a comment': 'Write a comment',
    'Comment': 'Comment',
    'Tokens owned': 'Tokens owned',
    'Profile Fields': 'Profile Fields',
    'Blocked': 'Blocked',
    'Add New Wallet': 'Add New Wallet',
    'CSV': 'CSV',
    'Upload CSV file': 'Upload CSV file',
    'ⓘ The file should only have a wallet address (or ENS) per row and no headers': 'ⓘ The file should only have a wallet address (or ENS) per row and no headers',
    'Import CSV': 'Import CSV',
    'Whitelisted Wallets': 'Whitelisted Wallets',
    'Tags Applied': 'Tags Applied',
    'Refused Wallets': 'Refused Wallets',
    'There was a problem listing all the whitelisted wallets. Please contact support on discord': 'There was a problem listing all the whitelisted wallets. Please contact support on discord',
    'Whitelist Wallet Created': 'Whitelist Wallet Created',
    'The whitelist wallet has been created.': 'The whitelist wallet has been created.',
  },
  'it': {
    'Name': '',
    'Description': '',
    'Submit': 'Invia',
    'Items.header': 'Ciao 1 Kind',
    'Items.header.offline': 'Ciao offline 1 Kind',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Dimenticato le credenziali? Clicca',
    'ForgotPassword.here': 'qui',
    'NewPassword': 'Nuova Password',
    'NewPasswordConfirmation': 'Conferma Nuova Password',
    'PasswordConfirmation': 'Conferma Password',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Ciao 1 Kind',
    'Home.header.offline': 'Ciao offline 1 Kind',
  },
}
