// import React from "react";
import { getProjectAttributes } from "@bkry/bowline-redux/projects";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Alert, Modal, ModalBody } from "reactstrap";
import moment from "moment";
import classNames from "classnames";

const weeksBetween = (d1, d2) => {
  return (d2 - d1) / (7 * 24 * 60 * 60 * 1000);
};

const PendingOrdersAlert = (props, context) => {
  const { projectId } = useParams();
  const projectData = useSelector((state) =>
    getProjectAttributes(state, projectId)
  );
  const dueAtString = projectData?.pending_orders_since;
  if (!dueAtString) return "";
  const dueAt = new Date(dueAtString);
  const now = new Date();
  const weeks = weeksBetween(dueAt, now);
  if (weeks < 0) return "";
  const dueAtMoment = moment(dueAt).calendar();
  const dueTwoWeeks = moment(dueAt).add(2, "weeks").calendar();
  const dueThreeWeeks = moment(dueAt).add(3, "weeks").calendar();
  let alertLevel = 1;
  if (weeks > 1) alertLevel = 2;
  if (weeks > 2) alertLevel = 3;
  if (weeks > 3) alertLevel = 4;

  const alertColors = {
    1: "warning",
    2: "warning",
    3: "danger",
    4: "danger",
  };

  const alertBody = {
    1: context.t("You have a pending order since {dueDate}", {
      dueDate: dueAtMoment,
    }),
    2: context.t(
      "You have a pending order since {dueDate}. The public app for this project will be deprecated on {dueTwoWeeks} ",
      {
        dueDate: dueAtMoment,
        dueTwoWeeks: dueTwoWeeks,
      }
    ),
    3: context.t(
      "You have a pending order since {dueDate}. The public app for this project already shows a deprecation warning since {dueTwoWeeks} and it will be deactivated {dueThreeWeeks} ",
      {
        dueDate: dueAtMoment,
        dueTwoWeeks: dueTwoWeeks,
        dueThreeWeeks: dueThreeWeeks,
      }
    ),
    4: context.t(
      "You have a pending order since {dueDate}. The public app for this project is currently deactivated! ",
      {
        dueDate: dueAtMoment,
      }
    ),
  };

  const alertFooter = {
    1: context.t("Please check your invoices"),
    2: context.t("Please check your invoices!"),
    3: context.t("Please check your invoices!!"),
    4: context.t("Please check your invoices!!!"),
  };

  return (
    <>
      {alertLevel <= 3 && (
        <Alert color={alertColors[alertLevel]}>
          <h4 className="alert-heading">
            {context.t("You have some pending orders")}
          </h4>
          <p>{alertBody[alertLevel]}</p>
          <hr />
          <p className="mb-0">{alertFooter[alertLevel]}</p>
        </Alert>
      )}
      {alertLevel > 3 && (
        <Modal
          isOpen
          size="xl"
          centered
          className="projectactivationmodal"
          backdropClassName={classNames(
            "projectactivationmodal__backdrop",
            "projectactivationmodal__backdrop--notactivated"
          )}
          scrollable
        >
          <ModalBody className="m-3 m-md-5">
            <h1 className="text-center text-danger">
              {context.t("Your project is currently deactivated")}
            </h1>
            <p className="text-center">{alertBody[alertLevel]}</p>
            <p className="text-center">
              {context.t(
                "Please check your invoices and pay the pending orders to reactivate your project."
              )}
            </p>
            <p className="text-center">
              {context.t("To delete your account please contact us at") + " "}
              <a
                href="mailto:support@bowline.app"
                target="_blank"
                rel="noreferrer"
              >
                {context.t("support@bowline.app")}
              </a>
            </p>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

PendingOrdersAlert.contextTypes = {
  t: PropTypes.func.isRequired,
};

PendingOrdersAlert.propTypes = {};

PendingOrdersAlert.defaultProps = {};

export default PendingOrdersAlert;
