import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

const BillingForm = ({ onSubmit, defaultValues = {} }, context) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="company_name">{context.t("Company Name")}</Label>

            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(v) => {
                    field.onChange(v);
                  }}
                  type="text"
                  placeholder="Company Name"
                  id="company_name"
                  invalid={errors?.company_name}
                />
              )}
              name="company_name"
              control={control}
              rules={{ required: true }}
            />

            {errors.company_name?.type === "required" && (
              <p className="text-danger small">
                {context.t("company name is required")}
              </p>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="email">{context.t("Email")}</Label>
            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(v) => {
                    field.onChange(v);
                  }}
                  type="email"
                  placeholder="Email"
                  id="email"
                  invalid={errors?.billing_email}
                />
              )}
              name="billing_email"
              control={control}
              rules={{ required: true }}
            />

            {errors.billing_email?.type === "required" && (
              <p className="text-danger small">
                {context.t("email is required")}
              </p>
            )}
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="billing_address">{context.t("Billing Address")}</Label>

            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(v) => {
                    field.onChange(v);
                  }}
                  type="text"
                  placeholder="Billing Address"
                  id="billing_address"
                  invalid={errors?.billing_address}
                />
              )}
              name="billing_address"
              control={control}
              rules={{ required: true }}
            />

            {errors.billing_address?.type === "required" && (
              <p className="text-danger small">
                {context.t("billing address is required")}
              </p>
            )}
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="postal_code">{context.t("Postal Code")}</Label>

            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(v) => {
                    field.onChange(v);
                  }}
                  type="text"
                  placeholder="Postal Code"
                  id="postal_code"
                  invalid={errors?.postal_code}
                />
              )}
              name="postal_code"
              control={control}
              rules={{ required: true }}
            />

            {errors.postal_code?.type === "required" && (
              <p className="text-danger small">
                {context.t("postal code is required")}
              </p>
            )}
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="city">{context.t("City")}</Label>

            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(v) => {
                    field.onChange(v);
                  }}
                  type="text"
                  placeholder="City"
                  id="city"
                  invalid={errors?.city}
                />
              )}
              name="city"
              control={control}
              rules={{ required: true }}
            />

            {errors.city?.type === "required" && (
              <p className="text-danger small">
                {context.t("city is required")}
              </p>
            )}
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="country">{context.t("Country")}</Label>

            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(v) => {
                    field.onChange(v);
                  }}
                  type="text"
                  placeholder="Country"
                  id="country"
                  invalid={errors?.country}
                />
              )}
              name="country"
              control={control}
              rules={{ required: true }}
            />

            {errors.country?.type === "required" && (
              <p className="text-danger small">
                {context.t("country is required")}
              </p>
            )}
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="vat_id">{context.t("VAT Number")}</Label>

            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(v) => {
                    field.onChange(v);
                  }}
                  type="text"
                  placeholder="VAT Number"
                  id="vat_id"
                  invalid={errors?.vat_id}
                />
              )}
              name="vat_id"
              control={control}
              rules={{ required: true }}
            />

            {errors.vat_id?.type === "required" && (
              <p className="text-danger small">
                {context.t("vat id is required")}
              </p>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 6, offset: 6 }}>
          <Button type="submit" block>
            {context.t("Submit")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

BillingForm.contextTypes = {
  t: PropTypes.func,
};

BillingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
};

BillingForm.defaultProps = {};

export default BillingForm;
