/**
 * The Home container
 */

import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React from "react";
import { MdOutlineCopyright, MdOutlineLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

/**
 * Home container used as app default landing page
 *
 */

const SidebarOnboarding = (props, context) => {
  const { children, mobileSidebarActive } = props;
  const currentYear = new Date().getFullYear();

  const dispatch = useDispatch();

  const { connector } = useWeb3React();

  const handleDisconnect = () => {
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    document.cookie =
      "creator-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    dispatch(push(`/`));
  };

  return (
    <div
      className={classNames(
        `d-flex flex-column flex-shrink-0 py-2 bg-dark text-light sidebar`,
        { active: mobileSidebarActive }
      )}
    >
      <div className="d-flex flex-column sidebar__scrollcontainer ">
        <div className="sidebar__menu nav-pills flex-column mb-auto mt-5 mx-3 px-3 main-menu flex-grow-1 position-relative">
          {children}
        </div>
        <div className="nav nav-pills flex-column main-menu mb-3">
          <div
            className="menu-item text-decoration-none small"
            style={{ cursor: "pointer" }}
          >
            <div className="nav-link" onClick={() => handleDisconnect()}>
              <div className={classNames("d-flex menuwrapper")}>
                <div>
                  <MdOutlineLogout size={20} />
                </div>
                <span className="ms-2">{context.t("Log Out")}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="small text-light mb-3 d-flex allign-items-center"
          style={{ marginLeft: "2rem", marginRight: "2rem" }}
        >
          <div id="sidebar__copyright">
            <MdOutlineCopyright />
          </div>
          <span className="ms-2">Bowline {currentYear}</span>
        </div>
      </div>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
SidebarOnboarding.contextTypes = {
  t: PropTypes.func,
};

SidebarOnboarding.propTypes = {
  mobileSidebarActive: PropTypes.bool,
  children: PropTypes.node,
};

SidebarOnboarding.defaultProps = {};

/** export the component as redux connected component */
export default withRouter(SidebarOnboarding);
