/**
 * The Dashboard container
 */
import { listProjects } from "@bkry/bowline-redux/projects";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route } from "react-router";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import Navbar from "../../components/Navbar";
import PendingOrdersAlert from "../../components/PendingOrdersAlert";
import { Sidebar } from "../../components/Sidebar";
const CreatorLayoutRoute = ({
  component: Component,
  fluidContainer,
  sidebarHidden,
  ...rest
}) => {
  const location = useLocation();
  const [sidebarMinimized, setSidebarMinimized] = useState(false);

  const [mobileSidebarActive, setMobileSidebarActive] = useState(false);
  const toggleMobileSidebar = () =>
    setMobileSidebarActive(!mobileSidebarActive);

  useEffect(() => {
    setMobileSidebarActive(false);
  }, [location]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProjects());
  }, [dispatch]);

  // TODO if not signed in, redirect to login
  // TODO if signed in but no project, redirect to project creation (wait until list projects is finished...)

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Navbar
              toggleMobileSidebar={toggleMobileSidebar}
              sidebarMinimized={sidebarMinimized}
            />
            <div className="dashboard">
              {!sidebarHidden && (
                <Sidebar
                  onCollapse={(sidebarMinimized) => {
                    setSidebarMinimized(sidebarMinimized);
                  }}
                  mobileSidebarActive={mobileSidebarActive}
                />
              )}
              <div
                className={cx("dashboardcontent", {
                  "dashboardcontent--sidebarcollapsed": sidebarMinimized,
                  "dashboardcontent--sidebarhidden": sidebarHidden,
                })}
              >
                <Container fluid={fluidContainer}>
                  <PendingOrdersAlert />
                  <Component {...props} />
                </Container>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

/**  define proptype for the 'translation' function  */
CreatorLayoutRoute.contextTypes = {
  t: PropTypes.func,
};

CreatorLayoutRoute.propTypes = {
  component: PropTypes.func,
  fluidContainer: PropTypes.bool,
  sidebarHidden: PropTypes.bool,
};

CreatorLayoutRoute.defaultProps = {
  fluidContainer: false,
  sidebarHidden: false,
};

/** export the component as redux connected component */
export default CreatorLayoutRoute;
