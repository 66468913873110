/**
 * The Dashboard container
 */
import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";

/**
 * Dashboard container, the landing page for logged in users
 */

const ContentCategoryDetail = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ContentCategoryDetail" */ "./ContentCategoryDetail"
    ),
  loading: () => null,
  modules: ["ContentCategoryDetail"],
});

const ContentCategories = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ "./ContentCategories"),
  loading: () => null,
  modules: ["ContentCategories"],
});

const ContentCategoriesCreate = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Home" */ "./ContentCategoriesCreate"),
  loading: () => null,
  modules: ["ContentCategoriesCreate"],
});

const ContentCategoriesEdit = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Home" */ "./ContentCategoriesEdit"),
  loading: () => null,
  modules: ["ContentCategoriesEdit"],
});

const ContentCategoriesRoot = () => {
  return (
    <div className="Categories">
      <Switch>
        <Route
          path={`/projects/:projectId/categories/:categoryId/edit`}
          component={ContentCategoriesEdit}
        />
        <Route
          path={`/projects/:projectId/categories/new`}
          component={ContentCategoriesCreate}
        />

        <Route
          path={`/projects/:projectId/categories/:categoryId`}
          component={ContentCategoryDetail}
        />

        <Route
          path={`/projects/:projectId/categories`}
          component={ContentCategories}
        />
      </Switch>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
ContentCategoriesRoot.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default ContentCategoriesRoot;
