import React from "react";
import PropTypes from "prop-types";
import { UncontrolledTooltip } from "reactstrap";

/**
 * Table container used as app default landing page
 */
const LicenseTooltip = (props, context) => {
  const { target } = props;

  return (
    <UncontrolledTooltip placement="right" target={target}>
      {context.t("Upgrade your license to have access to this feature.")}
    </UncontrolledTooltip>
  );
};

LicenseTooltip.contextTypes = {
  t: PropTypes.func,
};

/**  define proptypes for LicenseTooltip  */
LicenseTooltip.propTypes = {
  target: PropTypes.string,
  text: PropTypes.string,
};

/** export the component as redux connected component */
export default LicenseTooltip;
