/**
 * The BetaFeature component
 */
import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import InviteOnlyIcon from "../../assets/icons/invitation.svg";
const BetaFeature = () => {
  return (
    <div>
      <Container>
        <div className="text-center bg-white rounded-3 p-3 mt-5 mb-5 w-50 m-auto shadow-sm">
          <img src={InviteOnlyIcon} height={150} />
          <p className="mt-3">
            <strong>This is a invite only beta feature.</strong>
          </p>
          <p>
            We are sorry, this feature can only be accessed by selected
            projects. We are currently testing it in closed only beta to fix
            last issues and improve the usability.
          </p>
          <p className="small text-secondary">
            Please contact our support if you are interested in participating in
            the invite only beta phase.
          </p>
        </div>
      </Container>
    </div>
  );
};
/**  define proptype for the 'translation' function  */
BetaFeature.contextTypes = {
  t: PropTypes.func,
};
export default BetaFeature;
