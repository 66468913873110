import {
  getProjectAttributes,
  updateProject,
} from "@bkry/bowline-redux/projects";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaEthereum } from "react-icons/fa6";
import {
  MdOutlineArrowBack,
  MdOutlineCreditCard,
  MdOutlineReceipt,
  MdSync,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Modal,
  ModalBody,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import BillingForm from "../../BillingForm";
import PricingPlans from "./utils/PricingPlans";
import SelectPass from "./utils/SelectPass";
import moment from "moment";
/**
 * ProjectActivationModal
 */
const ProjectActivationModal = (props, context) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const project = useSelector((state) =>
    getProjectAttributes(state, projectId)
  );

  const pricingPlans =
    useSelector((state) => state?.projects?.pricingPlans) || [];

  const validLicense = [
    "gold",
    "silver",
    "platinum",
    "demo",
    "analytics",
  ].includes(project?.license_type);

  /* Steps:
  1 Initial
  2 Select Payment Method
  10 Nft Selection
  100 Confirmation
  */
  const [checkoutStep, setCheckoutStep] = useState(validLicense ? 100 : 1);
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
  const [passActivated, setPassActivated] = useState(validLicense);
  const [isOpen, setIsOpen] = useState(
    passActivated ? false : !project ? false : true
  );
  const toggle = () => setIsOpen(!isOpen);

  const [selectedPass, setSelectedPass] = useState(null);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const toggleConfirmation = () => setConfirmationOpen(!confirmationOpen);

  // ! TODO remove allt his mocking
  const [activating, setActivating] = useState(false);

  const handleCofirmation = () => {
    setConfirmationOpen(false);
    setActivating(true);
  };

  useEffect(() => {
    if (activating) {
      setTimeout(() => {
        setActivating(false);
        setPassActivated(true);
      }, 10 * 1000);
    }
  }, [activating]);

  // ! TODO needs to get populated from api
  const [ownedNftPasses, setOwnedNftPasses] = useState([]);
  const loadNftPasses = () => {
    const newPasses = [
      {
        id: "1",
        name: "Bowline Gold Pass #1",
        bound_to_project: null,
        img_url: "https://picsum.photos/150/",
        valid_until: null,
        max_days_valid: 365,
      },
      {
        id: "2",
        name: "Bowline Gold Pass #2",
        bound_to_project: null,
        img_url: "https://picsum.photos/150/",
        valid_until: "2023-02-25T09:09:26.047Z",
        max_days_valid: 365,
      },
      {
        id: "3",
        name: "Bowline Gold Pass #3",
        bound_to_project: "570299d3-5b5f-447d-a29a-938170d731ed",
        img_url: "https://picsum.photos/150/",
        valid_until: "2023-04-20T09:09:26.047Z",
        max_days_valid: 365,
      },
      {
        id: "4",
        name: "Bowline Demo Pass #1",
        bound_to_project: null,
        img_url: "https://picsum.photos/150/",
        valid_until: null,
        max_days_valid: 30,
      },
    ];
    setOwnedNftPasses(newPasses);
    setCheckoutStep(10);
  };

  const renderSelectedPass = () => {
    const pass = ownedNftPasses.find((pass) => pass.id === selectedPass);
    if (!pass) return null;
    return (
      <Row className="justify-content-center py-5">
        <Col xs={6} lg={3}>
          <SelectPass pass={pass} selectedPass={selectedPass} />
        </Col>
      </Row>
    );
  };

  const currentSelectedPlan = pricingPlans.find(
    (plan) => plan.id === selectedPlan?.type
  );

  const handleSelectedPlan = (plan) => {
    setSelectedPlan(plan);
    setCheckoutStep(2);
  };

  const onBillingInfoSubmit = (data) => {
    dispatch(
      updateProject({
        projectId: projectId,
        project: {
          project_billing_info_attributes: { ...data },
          selected_plan_type: selectedPlan.type,
          selected_plan_period: selectedPlan.period,
        },
        callbackFnc: () => {
          setCheckoutStep(100);
          setPassActivated(true);
        },
      })
    );
  };

  const updateAutoRenewal = (value) => {
    dispatch(
      updateProject({
        projectId: projectId,
        project: {
          license_auto_renewal: value,
        },
      })
    );
  };

  if (!project) return null;

  return (
    <>
      {project && (
        <Badge
          onClick={() => setIsOpen(true)}
          style={
            project?.license_type === "analytics"
              ? { background: "#940e04" }
              : null
          }
          className={`cursor-pointer ms-3 ${
            project?.license_type === "silver" ? "text-dark" : ""
          }`}
          color={
            project?.license_type === "gold"
              ? "warning"
              : project?.license_type === "silver"
              ? "light"
              : project?.license_type === "platinum"
              ? "primary"
              : project?.license_type === "demo"
              ? "info"
              : project?.license_type === "analytics"
              ? null
              : "secondary"
          }
        >
          {validLicense ? project?.license_type : "no license"}
        </Badge>
      )}
      <Modal
        isOpen={isOpen}
        toggle={passActivated ? toggle : () => {}}
        size="xl"
        centered
        className="projectactivationmodal"
        backdropClassName={classNames("projectactivationmodal__backdrop", {
          "projectactivationmodal__backdrop--notactivated": !passActivated,
        })}
        scrollable
      >
        <ModalBody className="m-3 m-md-5">
          {/* {checkoutStep != 100 && (
            <>
              <div className="text-center mb-3">
                <Badge
                  pill
                  color={ownedNftPasses?.length > 0 ? "primary" : "info"}
                  className="px-3"
                >
                  {ownedNftPasses?.length > 0
                    ? context.t("Please select one of the available plans")
                    : context.t("We haven’t found any Pass in your Wallet")}
                </Badge>
              </div>
              <p className="h3 fw-bold text-center">
                {context.t("You need to activate your Bowline license.")}
              </p>
            </>
          )} */}
          {checkoutStep == 1 && (
            <>
              <div className="text-center mb-3">
                <Badge pill color={"info"} className="px-3">
                  {context.t("This Project has no active license")}
                </Badge>
              </div>
              <p className="h3 fw-bold text-center mb-4">
                {context.t("Please select a Project Plan")}
              </p>
              <PricingPlans
                selectedPlan={selectedPlan}
                setSelectedPlan={handleSelectedPlan}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                pricingPlans={pricingPlans}
              />
              {false && (
                <div className="my-5 text-center">
                  <h2>OR</h2>
                  <Row className="mt-3 justify-content-center">
                    <Col lg="4" xl="3">
                      <Button
                        onClick={loadNftPasses}
                        color="primary"
                        size="lg"
                        block
                      >
                        {context.t("Use NFT pass")}
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </>
          )}
          {checkoutStep == 2 && (
            <>
              <Row>
                <Col md={3}>
                  <Button
                    onClick={() => setCheckoutStep(1)}
                    color="primary"
                    outline
                    title={context.t("Back")}
                    className="mb-3"
                  >
                    <MdOutlineArrowBack />
                  </Button>
                </Col>
                <Col md={6}>
                  <div className="text-center mb-3">
                    <Badge pill color={"info"} className="px-3">
                      {context.t("This Project has no active license")}
                    </Badge>
                  </div>
                  <p className="h3 fw-bold text-center mb-4">
                    {context.t("Select Payment Method")}
                  </p>
                </Col>
              </Row>
              <div>
                {currentSelectedPlan && (
                  <div>
                    <p className="small">
                      {context.t("Selected Plan")}: {currentSelectedPlan.name}{" "}
                      {selectedPlan.period === "monthly"
                        ? currentSelectedPlan.monthly_price
                        : currentSelectedPlan.yearly_price}
                      € / {context.t("month")} billed {selectedPlan.period}
                      {selectedPlan.period === "yearly" && (
                        <> =&gt; {currentSelectedPlan.yearly_price * 12} €</>
                      )}
                    </p>
                  </div>
                )}
                <Row>
                  <Col md={4}>
                    <Button
                      onClick={() => setSelectedPaymentMethod(1)}
                      color="primary"
                      block
                      active={selectedPaymentMethod === 1}
                    >
                      <MdOutlineReceipt size={32} />
                      <br />
                      {context.t("Invoice")}
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      onClick={() => setSelectedPaymentMethod(2)}
                      color="primary"
                      block
                      active={selectedPaymentMethod === 2}
                    >
                      <MdOutlineCreditCard size={32} />
                      <br />
                      {context.t("Credit Card")}
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      onClick={() => setSelectedPaymentMethod(3)}
                      color="primary"
                      block
                      active={selectedPaymentMethod === 3}
                    >
                      <FaEthereum size={32} />
                      <br />
                      {context.t("Ethereum")}
                    </Button>
                  </Col>
                </Row>
                <TabContent activeTab={selectedPaymentMethod}>
                  <TabPane tabId={0}>
                    <div className="my-5 py-5">
                      <p className="h1 fw-bold text-center mt-5">
                        ☝️{context.t("Select Method")}
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId={1}>
                    <p className="h3 fw-bold text-center mt-3">
                      {context.t("Billing Info")}
                    </p>
                    <BillingForm onSubmit={onBillingInfoSubmit} />
                  </TabPane>
                  <TabPane tabId={2}>
                    <div className="my-5 py-5">
                      <p className="h1 fw-bold text-center mt-5">
                        {context.t("Not active yet")}
                      </p>
                      <p className="text-center">
                        {context.t(
                          "If you want to pay with {method} please contact us via mail",
                          { method: context.t("creditcard") }
                        )}{" "}
                        <a href="support@bowline.com" className="text-primary">
                          support@bowline.com
                        </a>
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId={3}>
                    <div className="my-5 py-5">
                      <p className="h1 fw-bold text-center mt-5">
                        {context.t("Not active yet")}
                      </p>
                      <p className="text-center">
                        {context.t(
                          "If you want to pay with {method} please contact us via mail",
                          { method: context.t("Ethereum") }
                        )}{" "}
                        <a href="support@bowline.com" className="text-primary">
                          support@bowline.com
                        </a>
                      </p>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </>
          )}
          {checkoutStep == 10 && !passActivated && (
            <>
              {activating ? (
                <>
                  <div className="my-5 text-center">
                    <MdSync className="icon-spin-reverse" size={64} />
                  </div>
                  <p className="h3 fw-bold text-center">
                    {context.t("Activating pass")}
                  </p>
                </>
              ) : (
                <>
                  {ownedNftPasses?.length > 0 ? (
                    <>
                      <p className="text-center">
                        {context.t(
                          "Select one of these passes to bind it to your project."
                        )}
                      </p>
                      <Row className="justify-content-center mb-5">
                        {ownedNftPasses.map((pass) => (
                          <Col xs={6} lg={3} key={pass.id} className="mb-3">
                            <SelectPass
                              pass={pass}
                              setSelectedPass={setSelectedPass}
                              selectedPass={selectedPass}
                            />
                          </Col>
                        ))}
                      </Row>
                      <div className="text-center">
                        <Button
                          onClick={() => setConfirmationOpen(true)}
                          disabled={!selectedPass}
                          size="lg"
                          color="secondary"
                        >
                          {context.t("Activate Pass")}
                        </Button>
                      </div>
                    </>
                  ) : (
                    "No passes found"
                  )}
                </>
              )}
            </>
          )}
          {checkoutStep == 100 && (
            <PricingPlans
              selectedPlan={selectedPlan}
              selectedPeriod={
                project?.license_payment_plan === "yearly_subscription"
                  ? "yearly"
                  : "monthly"
              }
              currentPlanType={project?.license_type}
              pricingPlans={pricingPlans}
            />
          )}
          {passActivated && (
            <>
              <p className="h3 fw-bold text-center mt-3">
                {context.t("Project is activated")}
              </p>
              {renderSelectedPass()}
              <Row className="justify-content-center mt-4">
                <Col lg={4}>
                  {project.license_auto_renewal &&
                    project.license_valid_until && (
                      <div className="small text-center">
                        <p>
                          {context.t(
                            "This subscription will be automatically renewed on {date}.",
                            {
                              date: moment(
                                project.license_valid_until
                              ).calendar(),
                            }
                          )}
                        </p>
                        <p>
                          {context.t(
                            "You can cancel the auto-renewal at any time and your subscription will still be valid until expiration"
                          )}
                        </p>
                        <Button
                          onClick={() => updateAutoRenewal(false)}
                          color="link"
                          block
                        >
                          {context.t("Unsubscribe")}
                        </Button>
                      </div>
                    )}
                  {!project.license_auto_renewal &&
                    project.license_valid_until && (
                      <div className="small text-center">
                        <p>
                          {context.t(
                            "This subscription will be valid until {date}.",
                            {
                              date: moment(
                                project.license_valid_until
                              ).calendar(),
                            }
                          )}
                        </p>
                        <p>
                          {context.t(
                            "You can activate the auto-renewal at any time."
                          )}
                        </p>
                        <Button
                          onClick={() => updateAutoRenewal(true)}
                          color="success"
                          block
                        >
                          {context.t("Activate auto-renewal")}
                        </Button>
                      </div>
                    )}
                </Col>
              </Row>

              <Row className="justify-content-center mt-4">
                <Col lg={4}>
                  <Button onClick={toggle} color="primary" block size="lg">
                    {context.t("Close")}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={confirmationOpen}
        toggle={toggleConfirmation}
        size="lg"
        centered
        scrollable
      >
        <ModalBody>
          <p className="h3 fw-bold text-center">
            {context.t("Confirm Activation")}
          </p>
          {renderSelectedPass()}
          <div className="small text-center mx-5">
            <p>
              {context.t(
                "As soon as your Bowline Pass is assigned to a Project and activated, it cannot be assigned to any other Bowline Project without first being deactivated from the current one. Each pass can be resold at NFT Marketplaces. With the duration of it being the remaining time period available at the moment of the sale."
              )}
            </p>
            <p>
              {context.t(
                "As long as a Pass is activated and assigned to a Project, it cannot be sold at NFT Marketplaces. It must be deactivated before continuing with the sale."
              )}
            </p>
          </div>
          <Row className="mt-5 justify-content-center">
            <Col lg="4">
              <Button
                onClick={handleCofirmation}
                color="secondary"
                size="lg"
                block
              >
                {context.t("Confirm")}
              </Button>
            </Col>
          </Row>
          <Row className="mt-3 justify-content-center">
            <Col lg="4">
              <Button
                onClick={toggleConfirmation}
                color="primary"
                size="lg"
                outline
                block
              >
                {context.t("Cancel")}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

/**  define proptypes for ProjectActivationModal  */
ProjectActivationModal.propTypes = {};

/**  define defaultProps for ProjectActivationModal  */
ProjectActivationModal.defaulProps = {};

/**  define proptype for the 'translation' function  */
ProjectActivationModal.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default ProjectActivationModal;
