/**
 * The navbar container
 */
import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { Navbar, NavbarToggler } from "reactstrap";
import Logo from "../../assets/icons/bowline-logo.svg";
import LogoMarkWhite from "../../assets/icons/bowline-logomark-white.svg";
import FlashAlert from "../FlashAlert";
import ProjectActivationModal from "../Project/ProjectActivationModal";
import TrunkatedAddress from "../Utils/TrunkatedAddress";
import { getProjectAttributes } from "@bkry/bowline-redux/projects";

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Navigationbar = (props) => {
  const { account } = useWeb3React();
  const { projectId } = useParams();
  const { toggleMobileSidebar, sidebarMinimized } = props;

  const alertMessage = useSelector((state) => state.notifications.message);
  const alertColor = useSelector((state) => state.notifications.color);
  const alertTimeout = useSelector((state) => state.notifications.timeout);
  const alertLink = useSelector((state) => state.notifications.link);
  const alertOrigin = useSelector((state) => state.notifications.origin);
  const project = useSelector((state) =>
    getProjectAttributes(state, projectId)
  );
  return (
    <div
      style={props.style}
      className={classNames(
        "navigationbar ",
        {
          "navigationbar--sticky": props.isSticky,
        },
        { "navigationbar--sidebarminimized": sidebarMinimized }
      )}
    >
      <Navbar color="dark" dark expand="md">
        <NavbarToggler
          aria-label="navigation_toggle"
          onClick={toggleMobileSidebar}
        />
        <NavLink
          to={`/projects/${projectId ? `${projectId}/` : ""}`}
          className="title d-flex align-items-center text-white text-decoration-none toggle-menu-btn"
        >
          <span className="ms-1">
            <img src={Logo} className="logo ms-2" alt="icon" height={40} />
          </span>
          <img
            className={`d-none d-sm-inline ms-3`}
            src={LogoMarkWhite}
            alt="bowline"
            height={20}
          />
        </NavLink>
        {project && <ProjectActivationModal />}
        <div className="menu-item ms-auto">
          <div className="d-flex align-items-center justify-content-center menu-wrapper">
            <span className="me-2 text-light">
              {account && <TrunkatedAddress address={account} />}
            </span>
            <Avatar name={account} size={40} className="rounded-circle" />
          </div>
        </div>
      </Navbar>
      {alertMessage && (
        <FlashAlert
          color={alertColor || "success"}
          timeout={alertTimeout}
          origin={alertOrigin}
          link={alertLink}
        >
          {alertMessage}
        </FlashAlert>
      )}
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Navigationbar.propTypes = {
  handleLogoutAction: PropTypes.func,
  isSticky: PropTypes.bool,
  style: PropTypes.object,
  toggleMobileSidebar: PropTypes.func,
  sidebarMinimized: PropTypes.bool,
};

/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func,
};

export default Navigationbar;
