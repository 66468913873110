import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
} from "reactstrap";

const PricingPlans = (props, context) => {
  const {
    setSelectedPlan,
    selectedPlan,
    currentPlanType,
    selectedPeriod,
    setSelectedPeriod,
    pricingPlans,
  } = props;

  const currentPricingPlan = pricingPlans.find(
    (plan) => plan.key === currentPlanType
  );

  const handleSelection = (plan, period) => {
    setSelectedPlan({ type: plan.id, period });
  };
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  return (
    <div className="pricingplans mt-3">
      {!currentPlanType && (
        <div className="d-flex tabsBtnHolder">
          <ul>
            <li>
              <p
                id="monthly"
                className={
                  selectedPeriod === "monthly"
                    ? "active fw-bold"
                    : "cursor-pointer"
                }
                onClick={() => setSelectedPeriod("monthly")}
              >
                {context.t("Monthly")}
              </p>
            </li>
            <li>
              <p
                id="yearly"
                className={
                  selectedPeriod === "yearly"
                    ? "active fw-bold"
                    : "cursor-pointer"
                }
                onClick={() => setSelectedPeriod("yearly")}
              >
                {context.t("Yearly")}
              </p>
            </li>

            <li
              className={classNames("indicator", {
                indicator__yearly: selectedPeriod === "yearly",
              })}
            ></li>
          </ul>
        </div>
      )}
      <Row className="justify-content-center">
        {(currentPricingPlan ? [currentPricingPlan] : pricingPlans).map(
          (plan) => (
            <Col xl={{ size: 4 }} key={plan.id}>
              <Card
                className={classNames(
                  "h-100 rounded-4 overflow-hidden",
                  selectedPlan?.type === plan.id
                    ? "bg-success bg-opacity-10"
                    : ""
                )}
                style={
                  selectedPlan?.type === plan.id
                    ? { "--bs-border-opacity": "1" }
                    : { "--bs-border-opacity": "0" }
                }
              >
                <CardHeader className="py-3 h3 fw-bold text-center">
                  {plan.name}
                </CardHeader>
                <CardBody>
                  <p className="h5 fw-bold text-center mt-3 mb-0">
                    {selectedPeriod === "monthly"
                      ? plan.monthly_price
                      : plan.yearly_price}
                    € / {context.t("month")}
                  </p>
                  <p className="small text-center">
                    {selectedPeriod === "monthly"
                      ? context.t("monthly payment")
                      : context.t("annual payment")}
                  </p>
                  <div className="small">
                    <p className="text-capitalize">{context.t("Included")}</p>
                    <div>
                      <span className="w-75 d-inline-block">
                        {context.t("Tokens")}
                      </span>
                      <strong className="w-25 d-inline-block text-end">
                        {plan.tokens_included}
                      </strong>
                    </div>
                    <div>
                      <span className="w-75 d-inline-block">
                        {context.t("Contracts")}
                      </span>
                      <strong className="w-25 d-inline-block text-end">
                        {plan.contracts_included}
                      </strong>
                    </div>
                    <div>
                      <span className="w-75 d-inline-block">
                        {context.t("Collaborators")}
                      </span>
                      <strong className="w-25 d-inline-block text-end">
                        {plan.collaborators_included}
                      </strong>
                    </div>
                    <Collapse isOpen={showAdditionalInfo}>
                      <hr />
                      <p className="text-capitalize">{context.t("topups")}</p>
                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Per additional 721 token")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.additional_721_price} €
                        </strong>
                      </div>
                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Per additional 1155 token")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.additional_1155_price} €
                        </strong>
                      </div>
                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Additional contract per month")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.additional_contract_month_price} €
                        </strong>
                      </div>
                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Additional collaborator per month")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.additional_collaborator_month_price} €
                        </strong>
                      </div>
                      <hr />
                      <p className="text-capitalize">
                        {context.t("included unlockables")}
                      </p>
                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Contents")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.contents_included}
                        </strong>
                      </div>
                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Votings")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.votings_included}
                        </strong>
                      </div>
                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Partner Deals")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.deals_included}
                        </strong>
                      </div>
                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Events")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.events_included}
                        </strong>
                      </div>
                      <hr />
                      <p className="text-capitalize">
                        {context.t("additional unlockables")}
                      </p>
                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Additional Content per month")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.additional_content_month_price} €
                        </strong>
                      </div>

                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Additional Event per month")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.additional_event_month_price} €
                        </strong>
                      </div>

                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Additional voting per month")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.additional_voting_month_price} €
                        </strong>
                      </div>

                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Additional Deal per month")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.additional_deal_month_price} €
                        </strong>
                      </div>
                      <hr />
                      <p className="text-capitalize">
                        {context.t("Minting")} ({context.t("Minter Pays")})
                        <br />
                        <span className="small">
                          {context.t("Can be covered by creator optionally")}
                        </span>
                      </p>

                      <div>
                        <span className="w-75 d-inline-block">
                          {context.t("Price per mint")}
                        </span>
                        <strong className="w-25 d-inline-block text-end">
                          {plan.price_per_mint} €
                        </strong>
                      </div>
                      <hr />
                      <p className="text-capitalize">
                        {context.t("Customization Limitations")}
                      </p>
                      <div className="text-center">
                        <strong>{plan.customization_limitations}</strong>
                      </div>
                    </Collapse>
                  </div>
                  <Button
                    color="link"
                    onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                    style={{ zIndex: 3, position: "relative" }}
                    className="text-decoration-none"
                    block
                  >
                    {showAdditionalInfo ? (
                      <>
                        {context.t("show less")}
                        <MdKeyboardArrowUp className="ms-1" />
                      </>
                    ) : (
                      <>
                        {context.t("show more")}
                        <MdKeyboardArrowDown className="ms-1" />
                      </>
                    )}
                  </Button>
                  {!currentPricingPlan && (
                    <>
                      <Button
                        color="secondary"
                        className="mt-3 w-100 stretched-link text-capitalize"
                        onClick={() => handleSelection(plan, selectedPeriod)}
                      >
                        {context.t("select")}
                      </Button>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          )
        )}
      </Row>
    </div>
  );
};

PricingPlans.contextTypes = {
  t: PropTypes.func,
};

PricingPlans.propTypes = {
  setSelectedPlan: PropTypes.func,
  selectedPlan: PropTypes.object,
  selectedPeriod: PropTypes.string,
  setSelectedPeriod: PropTypes.func,
  currentPlanType: PropTypes.string,
  pricingPlans: PropTypes.array,
};

PricingPlans.defaultProps = {};

export default PricingPlans;
