import { getContracts } from "@bkry/bowline-redux/contracts";
import { getProjectRelationships } from "@bkry/bowline-redux/projects";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { MdOutlineImage } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import ContractSelect from "../../Utils/ContractSelect";

/**
 * TokenCreateModeModal
 */
const TokenCreateModeModal = (props, context) => {
  const { projectId } = useParams();
  const { trigger } = props;

  const projectRelationships = useSelector((state) =>
    getProjectRelationships(state, projectId)
  );

  const contracts = useSelector((state) =>
    getContracts(
      state,
      projectRelationships?.contracts?.data?.map((contract) => contract.id)
    )
  );

  const manageableContracts = contracts?.filter(
    (contract) => contract?.manageable_metadata
  );

  const noManageableContracts =
    manageableContracts && manageableContracts.length === 0;

  const [selectedContract, setSelectedContract] = useState(
    contracts?.[0]?.address
      ? { address: contracts[0].address, network: contracts[0].network }
      : null
  );

  useEffect(() => {
    if (!selectedContract) {
      setSelectedContract(
        contracts?.[0]?.address
          ? { address: contracts[0].address, network: contracts[0].network }
          : null
      );
    }
  }, [contracts]);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      {!trigger ? (
        <Button color="primary" size="lg" onClick={() => setIsOpen(true)}>
          {context.t("Create Token")}
        </Button>
      ) : (
        <>{React.cloneElement(trigger, { onClick: () => setIsOpen(true) })}</>
      )}
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{context.t("Create Token")}</ModalHeader>
        <ModalBody>
          {manageableContracts?.length > 0 && (
            <ContractSelect
              projectId={projectId}
              selectedContract={selectedContract}
              setSelectedContract={setSelectedContract}
              hideNonManageable
            />
          )}
          {noManageableContracts && (
            <Card className="mb-3">
              <CardBody>
                <Badge color="danger me-2">{context.t("WARNING")}</Badge>
                <p className="text-danger bg-light p-2 mb-0">
                  {context.t(
                    "You dont have any bowline contracts where you could create a token, ask your drop manager to add a contract to your project."
                  )}
                </p>
              </CardBody>
            </Card>
          )}
          <hr />
          <Card className="my-3">
            <CardBody>
              <Row>
                <Col xs={3}>
                  <MdOutlineImage className="w-100 h-100" />
                </Col>
                <Col xs={9}>
                  <p className="h6 fw-bold">
                    {!noManageableContracts && selectedContract ? (
                      <Link
                        to={`/projects/${projectId}/contracts/${selectedContract?.address},${selectedContract?.network}/tokens/new`}
                        className="stretched-link text-reset text-decoration-none"
                        onClick={() => setIsOpen(false)}
                      >
                        {context.t("Single Token")}
                      </Link>
                    ) : (
                      <span>{context.t("Single Token")}</span>
                    )}
                  </p>
                  <p>
                    {context.t(
                      "With the single token creation, you can easily create a new NFT for your collection. With only a few steps, your token is ready to be minted."
                    )}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card
            className="my-3 cursor-pointer"
            id="tokencreatemodal-massgeneration"
          >
            <CardBody>
              <Row>
                <Col xs={3}>
                  <MdOutlineImage className="w-50 h-50" />
                  <MdOutlineImage className="w-50 h-50" />
                  <MdOutlineImage className="w-50 h-50" />
                  <MdOutlineImage className="w-50 h-50" />
                </Col>
                <Col xs={9}>
                  <p className="h6 fw-bold">
                    <span>{context.t("Mass Token Generation")}</span>
                  </p>
                  <p>
                    {context.t(
                      "With the mass token generation, you can create a whole NFT collection within a few moments. Simply set up the collection's details and select the amount of tokens to be generated by our tool."
                    )}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <UncontrolledPopover
            target="tokencreatemodal-massgeneration"
            trigger="legacy"
            placement="top"
          >
            <PopoverHeader>{context.t("Disabled")}</PopoverHeader>
            <PopoverBody>
              <p className="text-dark">
                {context.t(
                  "This is an invite only/ beta only feature, please contact the Drop Manager for more information."
                )}
              </p>
            </PopoverBody>
          </UncontrolledPopover>
        </ModalBody>
      </Modal>
    </>
  );
};

/**  define proptypes for TokenCreateModeModal  */
TokenCreateModeModal.propTypes = {
  trigger: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

/**  define defaultProps for TokenCreateModeModal  */
TokenCreateModeModal.defaulProps = {};

/**  define proptype for the 'translation' function  */
TokenCreateModeModal.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default TokenCreateModeModal;
