import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Moment from "react-moment";
import { Badge, Card, CardBody, CardImg } from "reactstrap";

const SelectPass = (props, context) => {
  const { pass, setSelectedPass, selectedPass } = props;

  const handleSelection = () => {
    if (!setSelectedPass) return;
    if (selectedPass !== pass.id) setSelectedPass(pass.id);
    else setSelectedPass(null);
  };

  return (
    <Card
      onClick={!pass.bound_to_project ? handleSelection : undefined}
      className={classNames(
        "h-100 rounded-4 overflow-hidden border-success border border-2",
        selectedPass === pass.id ? "bg-success bg-opacity-10" : "",
        pass.bound_to_project ? "" : "cursor-pointer"
      )}
      style={
        selectedPass === pass.id
          ? { "--bs-border-opacity": "1" }
          : { "--bs-border-opacity": "0" }
      }
    >
      <CardImg
        src={pass.img_url}
        alt={pass.name}
        top
        className={pass.bound_to_project ? "opacity-50" : ""}
      />
      {pass.bound_to_project && (
        <Badge pill className="position-absolute top-0 end-0 m-3" color="info">
          {context.t("Bound")}
        </Badge>
      )}
      <CardBody>
        <div className="fw-bold">{pass.name}</div>
        {pass.max_days_valid && (
          <div className="small">
            {context.t("Max days")}: {pass.max_days_valid}
          </div>
        )}
        {pass.valid_until && (
          <div className="small">
            {context.t("Valid until")}:{" "}
            <Moment format={context.t("MM/DD/YYYY")}>{pass.valid_until}</Moment>
          </div>
        )}
        {pass.bound_to_project && (
          <div className="small">
            {context.t("Pass bound to other Project")}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

SelectPass.contextTypes = {
  t: PropTypes.func,
};

SelectPass.propTypes = {
  pass: PropTypes.object.isRequired,
  setSelectedPass: PropTypes.func,
  selectedPass: PropTypes.string,
};

SelectPass.defaultProps = {};

export default SelectPass;
