import React from "react";
import PropTypes from "prop-types";

/**
 * Table container used as app default landing page
 */
const TrunkatedAddress = (props) => {
  const { address, maxLength } = props;
  if (!address) return null;

  const firstAddressDigits = address?.substring(
    0,
    maxLength ? maxLength / 2 : 6
  );
  const lastAddressDigits = address?.slice(
    (maxLength ? maxLength / 2 : 4) * -1
  );

  return (
    <>
      {firstAddressDigits}...{lastAddressDigits}
    </>
  );
};

/**  define proptypes for TrunkatedAddress  */
TrunkatedAddress.propTypes = {
  address: PropTypes.string,
  maxLength: PropTypes.number,
};

/**  define defaultProps for TrunkatedAddress  */
TrunkatedAddress.defaulProps = {};

/** export the component as redux connected component */
export default TrunkatedAddress;
