/**
 * The BetaFeature component
 */
import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import InviteOnlyIcon from "../../assets/icons/invitation.svg";
const BetaFeature = (props, context) => {
  return (
    <div>
      <Container>
        <div className="text-center bg-white rounded-3 p-3 mt-5 mb-5 w-50 m-auto shadow-sm">
          <img src={InviteOnlyIcon} height={150} />
          <p className="mt-3">
            <strong>
              {context.t("This Feature is not Included in your License")}
            </strong>
          </p>
          <p>
            {context.t(
              "You need to upgrade your license to use this feature. If you face any issues of have questions about this feature please contact our support team."
            )}
          </p>
          <p className="small text-secondary">
            {context.t(
              "Please contact our support by email: support@bowline.app."
            )}
          </p>
        </div>
      </Container>
    </div>
  );
};
/**  define proptype for the 'translation' function  */
BetaFeature.contextTypes = {
  t: PropTypes.func,
};
export default BetaFeature;
